































































































import { PropSync, Component, Vue } from "vue-property-decorator";
import { nutricionista_conf_email } from "@/shared/dtos/nutricionista_conf_email";
@Component({
  components: {},
  $_veeValidate: { validator: "new" }
})
export default class ConfiguracionMailMarketing extends Vue {
  @PropSync("conf_email", {}) conf_emailsync!: nutricionista_conf_email;
  public configuracion_email: nutricionista_conf_email = new nutricionista_conf_email();
  public created() {
    this.configuracion_email = new nutricionista_conf_email(
      this.conf_emailsync
    );
  }
  public concatenear(variable: string) {
    this.configuracion_email.mensaje_email =
      this.configuracion_email.mensaje_email + variable;
  }
  public guardar() {
    this.conf_emailsync = new nutricionista_conf_email(
      this.configuracion_email
    );
    this.conf_emailsync.id_tipo_de_email = this.configuracion_email.id_tipo_de_email;
    this.conf_emailsync.id_nutricionista = this.configuracion_email.id_nutricionista;
    this.conf_emailsync.enviar_cada_dias = this.configuracion_email.enviar_cada_dias;
    this.conf_emailsync.email_habilitado = this.configuracion_email.email_habilitado;
    this.conf_emailsync.numero_citas_a_relizar = this.configuracion_email.numero_citas_a_relizar;
    this.conf_emailsync.maximo_emails_enviar = this.configuracion_email.maximo_emails_enviar;
    this.conf_emailsync.asunto_email = this.configuracion_email.asunto_email;
    this.conf_emailsync.mensaje_email = this.configuracion_email.mensaje_email;
    this.conf_emailsync.nombre_email = this.configuracion_email.nombre_email;
    this.$emit("save");
  }
  public async send_test() {
    this.$emit("save-test");
  }
}
